.ah-cart-item[data-v-d27c8420] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DCDCE5;
  padding: 12px 0;
  color: #00234A !important;
}
.ah-cart-item .ah-cart-item-label[data-v-d27c8420] {
    font-size: 17px;
    text-decoration: underline;
    padding-right: 20px;
    width: 70%;
    color: #00234A !important;
}
.ah-cart-item-controls[data-v-d27c8420] {
  display: flex;
  align-items: center;
}
.ah-cart-item-count[data-v-d27c8420] {
  margin-right: 18px;
}
.ah-cart-item-remove[data-v-d27c8420] {
  cursor: pointer;
  background: transparent;
  padding: 8px;
}
.ah-cart-item-remove img[data-v-d27c8420] {
    width: 30px;
}
