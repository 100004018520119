@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype");
}
@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700;
}

/*#afd186*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.ah-cart {
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  max-width: 100%;
  height: 100%;
  padding: 52px 24px;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 0 60px 0 rgba(0, 35, 74, 0.25);
  z-index: 9999;
}
.ah-cart div, .ah-cart p, .ah-cart h2, .ah-cart label {
    color: #00234A !important;
}
.ah-cart-section {
  margin-bottom: 40px;
}
.ah-cart-description {
  font-size: 16px;
  line-height: 24px;
}
.ah-cart-items {
  border-top: 1px solid #DCDCE5;
}
.ah-cart-close-button {
  background: transparent;
  position: absolute;
  top: 0;
  right: 6px;
  cursor: pointer;
}
.ah-cart-close-button:hover {
    background: transparent;
}
.ah-cart-close-button img {
    width: 24px;
}
.ah-cart h2 {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 12px;
}
.ah-cart h3 {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 24px;
  width: 100%;
}
.ah-cart-input-label {
  font-size: 16px;
  font-weight: 600;
}
.ah-cart input {
  height: 44px;
}
header.main-header .open-icon-white {
  display: none;
}
header.main-header.start .open-icon-white {
  display: block;
}
header.main-header.start .open-icon-blue {
  display: none;
}
header.main-header.start.active .open-icon-white {
  display: none;
}
header.main-header.start.active .open-icon-blue {
  display: block;
}
.mb-3 {
  width: 100%;
}
.mb-3.ah-half {
  width: 49%;
}
.mb-3.ah-half.left {
    margin-right: 4px;
}
body header.main-header .ah-cart-submit-button {
  width: 100%;
  height: 50px;
  background-color: #00234A;
  color: #FFAA64 !important;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
body header.main-header .ah-cart-submit-button:hover {
    background-color: #FFAA64 !important;
    color: #00234A !important;
}
body header.main-header .ah-cart-submit-button:disabled {
    background-color: lightgrey !important;
    color: #FFF !important;
    cursor: not-allowed;
}
.ah-form-consent {
  display: flex !important;
  align-items: center;
}
.ah-cart-form-select {
  width: 60px;
  padding: 4px;
}
.ah-open-cart {
  margin-right: 48px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100%;
}
.ah-open-cart .ah-cart-btn {
    position: relative;
    background: transparent;
}
.ah-open-cart .ah-cart-btn img {
      width: 30px;
      height: auto;
}
.ah-open-cart .ah-cart-btn span {
      position: absolute;
      background: red;
      display: flex;
      color: white;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      bottom: 26px;
      left: 12px;
}
.ah-submit-message {
  margin: 20px 0;
  padding: 8px;
  display: flex;
  border-radius: 4px;
  border: 1px solid;
}
.ah-submit-message.ah-failure-message {
    background: #ff9999;
    color: #800000;
    border-color: #800000;
}
.ah-submit-message.ah-success-message {
    background: #85e085;
    color: #155115;
    border-color: #155115;
}
@media only screen and (max-width: 991px) {
.ah-open-cart {
    display: none;
}
header.main-header .cart-container > div {
    justify-content: flex-start;
    margin-left: 30px;
}
.ah-cart {
    width: 100%;
}
}
